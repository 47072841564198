import React, { useEffect, useState } from "react";
import Header from "../components/header/Header";
import ReactPaginate from "react-paginate";
import "../css/payment.scss";
import { getPartnerPayments } from "../services/partner";
const Payment = () => {
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState();

  useEffect(() => {
    const fetchPosts = async () => {
      setLoading(true);
      const { data } = await getPartnerPayments(page);
      setPosts(data.data);
      setTotal(data.total);
      setLoading(false);
    };

    fetchPosts();
  }, [page]);

  const handlePageClick = (event) => {
    setPage(event.selected + 1);
  };

  if (loading && posts.length === 0) {
    return <h2>Loading...</h2>;
  }
  //Get current posts

  return (
    <div className="payment">
      <Header />
      <div className="container" style={{ padding: 20 }}>
        <div className="text-center">
          <h2>Payment history of the partner</h2>
        </div>

        {loading ? (
          <h2>Loading</h2>
        ) : (
          <table className="table table-striped">
            <thead className="thead-light text-center">
              <th>HISTORY</th>
              <th>PAID AMOUNT</th>
            </thead>
            <tbody>
              {posts.map((row) => (
                <tr key={row.id}>
                  <td> {new Date(row.add_date).toDateString()}</td>
                  <td>{row.cash} ₼</td>
                </tr>
              ))}
            </tbody>
          </table>
        )}

        {/* <Pagination pages={howManyPages} setCurrentPage={setCurrentPage} /> */}
        {/* <PaginatedItems itemsPerPage={4} />, */}
        <ReactPaginate
          breakLabel="..."
          nextLabel="next>"
          onPageChange={handlePageClick}
          pageRangeDisplayed={3}
          previousLabel="< previous"
          breakClassName={"page-item"}
          breakLinkClassName={"page-link"}
          pageCount={total !== 0 ? Math.ceil(total / 8) : 1}
          containerClassName={"pagination"}
          pageClassName={"page-item"}
          pageLinkClassName={"page-link"}
          previousClassName={"page-item"}
          previousLinkClassName={"page-link"}
          nextClassName={"page-item"}
          nextLinkClassName={"page-link"}
          activeClassName={"active"}
          renderOnZeroPageCount={1}
        />
      </div>
    </div>
  );
};

export default Payment;
