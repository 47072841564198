import React from "react";
import { Navigate } from "react-router-dom";
import { getUser } from "./services/partner";

const Protected = ({ children }) => {
  const token = localStorage.getItem("token");
    if (!token) {
    return <Navigate to="/" replace />;
  }
  getUser()
    .then((res) => {
    })
    .catch((err) => {
      return <Navigate to="/" replace />;
    });


  return children;
};

export default Protected;
