import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.scss";
import Login from "./screen/Login";
import Dashboard from "./screen/Dashboard";
import Protected from "./Protected";
import Payment from "./screen/Payment";
import ViewDashboard from "./screen/ViewDashboard";

function App() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/payment" element={<Payment />} />
          <Route
            path="/dashboard"
            element={
              <Protected>
                <Dashboard />
              </Protected>
            }
          />
          <Route
            path="/viewsDashboard"
            element={
              <Protected>
                <ViewDashboard />
              </Protected>
            }
          />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
