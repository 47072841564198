import {
  Container,
  Button,
  Grid,
  Paper,
  TextField,
  IconButton,
  InputAdornment,
} from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "../css/login.scss";
import { toast } from "react-toastify";
import logo from "../image/logo.png";
import { loginPartner } from "../services/partner";
const Login = () => {
  const navigate = useNavigate();
  const [values, setValues] = useState({
    email: "",
    pass: "",
    showPass: false,
  });
  const handleSubmit = (e) => {
    e.preventDefault();
    loginPartner(values.email, values.password)
      .then((res) => {
        localStorage.setItem("token", res.data.token);
        navigate("/dashboard");
      })

      .catch((err) => {
        toast.error("Belə istifadəçi mövcud deyil.");
      });
  };
  const handlePassVisibilty = () => {
    setValues({
      ...values,
      showPass: !values.showPass,
    });
  };
  useEffect(() => {
    localStorage.clear();
  }, []);

  return (
    <div className="boxes">
      <Container maxWidth="sm">
        <Grid
          container
          //   dark

          spacing={3}
          direction="column"
          justifyContent="center"
          style={{ minHeight: "100vh" }}
        >
          <div className="box-meta text-center pt-4">
            <img src={logo} alt="" />
            <h1>Partner management system</h1>
          </div>
          <Paper elelvation={2} sx={{ padding: 5 }}>
            <form onSubmit={handleSubmit}>
              <Grid container direction="column" spacing={2}>
                <Grid item>
                  <TextField
                    type="text"
                    fullWidth
                    label="Enter your email"
                    placeholder="Email Address"
                    variant="outlined"
                    required
                    onChange={(e) =>
                      setValues({ ...values, email: e.target.value })
                    }
                  />
                </Grid>

                <Grid item>
                  <TextField
                    type={values.showPass ? "text" : "password"}
                    fullWidth
                    label="Password"
                    placeholder="Password"
                    variant="outlined"
                    required
                    onChange={(e) =>
                      setValues({ ...values, password: e.target.value })
                    }
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={handlePassVisibilty}
                            aria-label="toggle password"
                            edge="end"
                          >
                            {values.showPass ? (
                              <VisibilityOffIcon />
                            ) : (
                              <VisibilityIcon />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>

                <Grid item>
                  <Button type="submit" fullWidth variant="contained">
                    Sign In
                  </Button>
                </Grid>
              </Grid>
            </form>
          </Paper>
        </Grid>
      </Container>
    </div>
  );
};

export default Login;
